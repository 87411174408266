/* eslint-disable */
import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import { getMonth, getYear } from "date-fns";
import { months, years } from "../../../config/constants";

// ✅ Custom input (unchanged, just keeps existing functionality)
const CustomInput = forwardRef(({ className, onClick, ...props }, ref) => {
  return (
    <div className={`date-picker flex-container align-center ${className}`}>
      <input type="text" style={{ width: "88%", paddingLeft: "2%", cursor: "pointer", height: "100%", borderRadius: "7px", border: "none", fontSize: "16px" }} placeHolder="DD.MM.YYYY" ref={ref} {...props} />
      <div className="date-icon" onClick={onClick} />
    </div>
  );
});

const TextInput = forwardRef(({ label, compulsory, style, inputStyle, disabled, className, custom, value, onChange, ...props }, ref) => {
  const [inputValue, setInputValue] = useState(value ? moment(value).format("DD.MM.YYYY") : "");
  const handleChangeRaw = (date) => {
    const newRaw = new Date(date.currentTarget.value);
    if (newRaw instanceof Date && !isNaN(newRaw)) setInputValue(newRaw);
  };

  return (
    <div className="input-container" style={style}>
      {label && (
        <label
          style={{
            display: "flex",
            width: "100%",
            fontSize: "0.8rem",
            marginBottom: "5px",
          }}
        >
          {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
        </label>
      )}
      <DatePicker
        renderCustomHeader={
          custom
            ? ({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                  </button>
                  <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                  </button>
                </div>
              )
            : undefined
        }
        customInput={<CustomInput value={inputValue} onChange={handleChangeRaw} />} // ✅ Uses custom input
        dateFormat="dd.MM.yyyy" // ✅ Correct format for react-datepicker
        selected={value}
        onChange={(date) => {
          setInputValue(moment(date).format("DD.MM.YYYY")); // ✅ Updates input field on date selection
          onChange(date);
        }}
        allowRawInput // ✅ Enables manual input
        onChangeRaw={(e) => handleChangeRaw(e)}
        wrapperClassName={className}
        ref={ref}
        {...props}
      />
    </div>
  );
});

export default TextInput;
